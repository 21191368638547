import { ReactNode, FC } from "react";
import { AppBar, Box, Toolbar, Theme } from "@mui/material";
import { useRecoilValue } from "recoil";
import { theoryAreaState, theoryChapterState } from "state/theoryState";
import { HeaderNavigation } from "utils/helpers/HeaderNavigation";
import { styleRoundedBottom } from "theme/styles";
import { useNavigate, useParams } from "react-router-dom";
import { useRouteConfiguration } from "hooks/useRouteConfiguration";
import useMediaQuery from "@mui/material/useMediaQuery";
import { HeaderDesktopMenu } from "../headerDesktopMenu";
import { useAppIntl } from "services/useAppIntl";

interface IHeaderProps {
  children?: ReactNode;
}

export const Header: FC<IHeaderProps> = ({ children }) => {
  const { areaId, chapterId } = useParams();
  const intl = useAppIntl();
  const area = useRecoilValue(theoryAreaState);
  const chapter = useRecoilValue(theoryChapterState);
  const title = chapterId && chapter ? chapter.title : area?.title;
  const topTitle = chapterId ? `/ ${area?.title}` : "";
  const routes = useRouteConfiguration();
  const isMediumOrSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const theoryTitle = intl.formatMessage({ id: "theory" });

  const navigateBack = () => {
    if (!areaId) return navigate(-1);

    // Navigate back up in the tree
    let path = chapterId ? routes.theoryChapters.path.replace(":areaId", areaId!) : routes.theory.path;

    // Always navigate to theory if on desktop
    if (!isMobile) path = routes.theory.path;

    navigate(path);
  };

  return (
    <AppBar position="fixed" elevation={0} sx={styleRoundedBottom}>
      <Toolbar>
        <HeaderNavigation
          iconSize={"large"}
          topTitle={`${theoryTitle} ${topTitle}`}
          title={title ? title : ""}
          onNavigate={navigateBack}
        />
        {!isMediumOrSmallScreen && <HeaderDesktopMenu />}
        <Box width="30%" />
      </Toolbar>
      {children}
    </AppBar>
  );
};
