// Api:s
import { appConfig } from "appConfig";

export const SUPPORT_LINK = "https://support.korkort.nu";
export const FEEDBACK_LINK = "https://feedback.korkort.nu";
export const SUPPORT_MAIL = "support@korkort.nu";

export const API_URL_CCS_CONSUMER = appConfig.ccsApiUrl + "/v1/Consumer/";
export const API_URL_CCS_DATA = appConfig.ccsApiUrl + "/v1/Data/";
export const API_URL_CCS_COMPOSITE = appConfig.ccsApiUrl + "/v1/Composite/";
export const API_URL_CCS_PROTOTYPE = appConfig.ccsApiUrl + "/v1/_Prototype/";
export const API_URL_BO_EDUCATION = appConfig.boApiUrl + "/v1/Education/";
export const API_URL_BO_PRODUCT = appConfig.boApiUrl + "/v1/Product/";
export const API_URL_BO_VOUCHER = appConfig.boApiUrl + "/v1/Voucher/";
export const API_URL_BO_PRODUCT_AVAILABLE_EDUCATIONS =
  API_URL_BO_PRODUCT + `available?salesChannelIdentifier=${appConfig.salesChannelId}`;
export const API_URL_BO_ME = appConfig.boApiUrl + "/v1/Me/";
export const API_URL_BO_EDUCATOR = appConfig.boApiUrl + "/v1/educator/";
export const API_URL_LRS_XAPI = appConfig.lrsApiUrl + "/v1/xapi/";
export const API_URL_CD_RESPONSE_PATTERN = appConfig.responsePatternApiUrl + "/v1/Response/";
export const API_URL_BO_AGREEMENT = appConfig.boApiUrl + "/v1/Agreement/";
export const API_URL_BO_SPEECH = appConfig.boApiUrl + "/v1/MicrosoftCognitiveServicesSpeech/";
export const API_URL_ASS_STATE = appConfig.assApiUrl + "/v1/State/" + appConfig.authClientId + "/";
export const API_URL_FFS = appConfig.ffsApiUrl + "/v1/FeatureFlags/" + appConfig.appId;
