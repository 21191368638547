import { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Tab, Tabs, Box, alpha, useTheme, Theme } from "@mui/material";
import {
  Icon,
  iconTheoryActive,
  iconTheory,
  iconCommentsQuestion,
  iconCommentsQuestionActive,
  iconPuzzle,
  iconPuzzleActive,
} from "theme/icons";
import FormattedMessage from "utils/helpers/FormattedMessage";
import { useRouteConfiguration } from "hooks/useRouteConfiguration";
import { testId } from "tests/testIdStrings";
import { TabPanel } from "./tabPanel";
import { SideMenu } from "./sideMenu";
import { desktopWidth640 } from "theme/theme";
import { TheoryTab } from "./TopNavigationTabs";

type Props = {
  outlet: any;
};

const styleActiveTab = (theme: Theme, active: boolean) => {
  const activeBgColor = alpha(theme.palette.primary.main, 0.04);

  return {
    height: "56px",
    color: `${theme.palette.primary.dark06}!important`,
    background: active ? activeBgColor : "white",
  };
};

export const TabsDesktop = ({ outlet }: Props) => {
  const location = useLocation();
  const theme = useTheme();
  const [activeTab, setActiveTab] = useState<TheoryTab>(TheoryTab.read); /* Todo: temporary context*/
  const routes = useRouteConfiguration();
  const [showSideMenu, setShowSideMenu] = useState(true);
  const { areaId, chapterId } = useParams();

  useEffect(() => {
    const tab = location.pathname.split("/").pop() as TheoryTab;
    setActiveTab(tab);
  }, [location]);

  return (
    <>
      <SideMenu showSideMenu={showSideMenu} setShowSideMenu={setShowSideMenu} />

      <Box maxWidth={desktopWidth640} mr="auto" ml="auto">
        <Tabs
          value={activeTab}
          textColor="inherit"
          variant="fullWidth"
          indicatorColor="primary"
          sx={{
            backgroundColor: theme.palette.common.white,
            borderTopLeftRadius: "15px",
            borderTopRightRadius: "15px",
            boxShadow: theme.shadows[2],
            position: "relative",
            zIndex: 2,
          }}
        >
          <Tab
            component={Link}
            to={routes.theoryRead.path.replace(":areaId", areaId!).replace(":chapterId", chapterId!)}
            icon={<Icon name={activeTab === TheoryTab.read ? iconTheoryActive : iconTheory} />}
            iconPosition="start"
            value={TheoryTab.read}
            data-testid={testId.menu.tab.read}
            label={<FormattedMessage id="common.title.read" />}
            sx={styleActiveTab(theme, activeTab === TheoryTab.read)}
          />
          <Tab
            component={Link}
            to={routes.theoryPractice.path.replace(":areaId", areaId!).replace(":chapterId", chapterId!)}
            icon={<Icon name={activeTab === TheoryTab.practice ? iconPuzzleActive : iconPuzzle} />}
            iconPosition="start"
            value={TheoryTab.practice}
            data-testid={testId.menu.tab.practice}
            label={<FormattedMessage id="common.title.practice" />}
            sx={styleActiveTab(theme, activeTab === TheoryTab.practice)}
          />
          <Tab
            component={Link}
            to={routes.theoryAnswer.path.replace(":areaId", areaId!).replace(":chapterId", chapterId!)}
            icon={<Icon name={activeTab === TheoryTab.answer ? iconCommentsQuestionActive : iconCommentsQuestion} />}
            iconPosition="start"
            value={TheoryTab.answer}
            data-testid={testId.menu.tab.answer}
            label={<FormattedMessage id="common.title.answer" />}
            sx={styleActiveTab(theme, activeTab === TheoryTab.answer)}
          />
        </Tabs>

        <TabPanel index={0} currentIndex={0}>
          {outlet}
        </TabPanel>
      </Box>
    </>
  );
};
