import { Statement, Actor, Context, Verb, Result, Activity, ActivityDefinition } from "@xapi/xapi";
import { API_URL_LRS_XAPI } from "utils/constants";
import { get, post } from "./httpService";
import {
  ItemAssessment,
  ItemConfigurationTypeAssessment,
  LRSProviderState,
  LRSRecord,
} from "@strmediaochitab/optima-component-library";
import { XapiKey } from "./lrsService";
import { ExtendedTreeNode, ReferenceKey } from "types/cds";
import {
  createProgressReport,
  createTestReport,
  getProgressedLearningObjectivesByQuestions,
  ItemKnowledge,
} from "./reportingService";
import { TestLabel } from "layout/test/test";
import { v4 as uuidv4 } from "uuid";

export const uriHost: string = "str-optima.se";

export interface IxApi {
  statementId: string;
  statementType: xApiStatementType;
  state: LRSProviderState;
  key?: XapiKey;
  assessment?: ItemAssessment;
  assessmentLabel?: TestLabel;
  learningStructure?: ExtendedTreeNode[];
  assessmentTypes?: {
    assessmentStudy?: ItemAssessment;
    assessmentFinal?: ItemAssessment;
    assessmentRoadsigns?: ItemAssessment;
  };
  questionState?: LRSRecord;
  assessmentState?: LRSProviderState;
}

const apiUrl = API_URL_LRS_XAPI;
type xApiStatementType = "question" | "assessment" | "assessment2";
type xApiActivityIdSegmentType = "question" | "assessment" | "activity" | "progressed";
type ExtendedActivityDefinition = ActivityDefinition & { interactionType?: string };

const getISO8601Time = (ms: number) => {
  const time = new Date(ms);
  const hours = time.getUTCHours();
  const minutes = time.getUTCMinutes();
  const seconds = time.getUTCSeconds();
  return `PT${hours}H${minutes}M${seconds}S`;
};

const createContext = (): Context => {
  return {
    platform: "STR-Optima",
    revision: "1.0.0",
  };
};

const createActor = (state: LRSProviderState): Actor => {
  return {
    objectType: "Agent",
    mbox: `mailto:${state.user.id}@${uriHost}`,
  };
};

const createVerb = (
  type:
    | "answered"
    | "attempted"
    | "completed"
    | "failed"
    | "passed"
    | "progressed"
    | "reported-progress"
    | "reported-result"
): Verb => {
  const uri = !type.startsWith("reported-") ? "http://adlnet.gov/expapi/verbs/" : `http://${uriHost}/expapi/verbs/`;
  return {
    id: `${uri}${type}`,
    display: { "en-US": type },
  };
};

const createResult = (state: LRSProviderState): Result | undefined => {
  // *** TEMP KOD tills vi avgjort var vi hanterar konvertering ***
  if (!state.result) return undefined;
  let response = undefined;
  let duration = undefined;

  // Konvertera här tills vidare...
  if (state.result.response) {
    let responseArray = JSON.parse(state.result.response);
    const numberArray = responseArray.map((r: string) => Number(r));
    response = JSON.stringify(numberArray);
  }

  if (state.result.duration && state.result.duration.start && state.result.duration.end) {
    duration = getISO8601Time(state.result.duration.end - state.result.duration.start);
  }

  // *** TEMP KOD ***

  return {
    completion: state.result!.completion,
    response: response,
    success: state.result!.success,
    score: {
      min: state.result.score ? state.result.score?.min : undefined,
      max: state.result.score ? state.result.score?.max : undefined,
      raw: state.result.score ? state.result.score?.raw : undefined,
      scaled: 0,
    },
    duration: duration,
  };
};

const createObjectActivity = (referenceKey: ReferenceKey, segmentType: xApiActivityIdSegmentType): Activity => {
  return {
    objectType: "Activity",
    id: `http://${uriHost}/${segmentType}/${referenceKey.contentId}/${referenceKey.versionId}`,
  };
};

const createActivityDefinition = (name: string, interactionType?: string): ExtendedActivityDefinition => {
  return {
    interactionType: interactionType,
    name: {
      "en-US": name,
    },
  };
};

const createAndSaveXapiStatement = async ({
  statementId,
  statementType,
  state,
  key,
  assessment,
  assessmentLabel,
  assessmentTypes,
  questionState,
  learningStructure,
  assessmentState,
}: IxApi) => {
  let response: string[] = [];
  switch (statementType) {
    case "question":
      if (state.result?.response)
        // answered
        response = await saveXapiStatement(createXapiStatementA(statementId, state, assessmentState));
      break;
    case "assessment2":
      // Attempted
      response = await saveXapiStatement(createXapiStatementF(statementId, state));
      break;
    case "assessment":
      // None,
      // Study = 1,
      // Quick,
      // Custom,
      // Final,
      // Roadsigns,

      // AssessmentA & Study & Final
      // AssessmentB & Study
      // AssessmentB & Study | Quick | Custom
      // AssessmentB & Roadsigns

      // TODO: Add try catch
      if (!assessmentTypes) throw new Error("assessmentTypes must be defined");
      if (!questionState) throw new Error("questionState must be defined");
      if (!learningStructure) throw new Error("learningStructure must be defined");
      if (!key) throw new Error("key must be defined");
      if (!assessmentLabel) throw new Error("assessmentLabel must be defined");
      if (!assessment) throw new Error("assessment must be defined");

      const assessmentStudy = assessmentTypes.assessmentStudy!;
      const assessmentFinal = assessmentTypes.assessmentFinal!;
      const assessmentRoadsigns = assessmentTypes.assessmentRoadsigns!;

      // Result from getProgressedLearningObjectivesByQuestions will include questions from assessmentRoadsigns as these are a subset of assessmentStudy
      const progressedLearningObjectivesByQuestions = getProgressedLearningObjectivesByQuestions(
        assessmentStudy,
        state,
        questionState,
        assessmentLabel
      );
      const progressedLearningObjectivesByAssessment = progressedLearningObjectivesByQuestions.questions.flatMap(
        (x) => x.learningObjectives
      );

      const rootActivityReferenceKey = getRootActivityReferenceKey(learningStructure);

      const studyReferenceKey = assessmentStudy.referenceKey;

      // Activity root
      const key1: XapiKey = {
        userId: key.userId,
        contentId: rootActivityReferenceKey.contentId,
        versionId: rootActivityReferenceKey.versionId!,
      };

      // Current assessment
      const key2: XapiKey = {
        userId: key.userId,
        contentId: assessment.referenceKey.contentId,
        versionId: assessment.referenceKey.versionId!,
      };

      // Study
      const key3: XapiKey = {
        userId: key.userId,
        contentId: studyReferenceKey.contentId,
        versionId: studyReferenceKey.versionId,
      };

      // Final
      const key4: XapiKey = {
        userId: key.userId,
        contentId: assessmentFinal.referenceKey.contentId,
        versionId: assessmentFinal.referenceKey.versionId,
      };

      // RoadSign
      const key5: XapiKey = {
        userId: key.userId,
        contentId: assessmentRoadsigns.referenceKey.contentId,
        versionId: assessmentRoadsigns.referenceKey.versionId,
      };

      // completed - A, B, C, D
      response = await saveXapiStatement(
        createXapiStatementB(statementId, state, progressedLearningObjectivesByQuestions)
      );

      if (assessment.configuration.type !== ItemConfigurationTypeAssessment.AssessmentC) {
        // progressed - A, B, D
        await saveXapiStatement(
          createXapiStatementC(state, rootActivityReferenceKey, progressedLearningObjectivesByAssessment)
        );
      }

      const statements1 = await getXapiStatements(key1, "progressed");
      const statements2 = await getXapiStatements(key3, "completed");
      const statements3 = await getXapiStatements(key4, "completed");
      const statements4 = await getXapiStatements(key5, "completed");
      const statementAllCompleted = [...statements2, ...statements3, ...statements4].sort((a, b) =>
        a.timestamp! > b.timestamp! ? 1 : -1
      );

      const progressReport = createProgressReport(assessmentStudy, statements1);
      await saveXapiStatement(createXapiStatementD(state, rootActivityReferenceKey, progressReport));

      if (assessment.configuration.type === ItemConfigurationTypeAssessment.AssessmentB) {
        // JUST A TEST FOR AN ACTIVITY
        // const progressReport = createProgressReport(assessment, statements1);
        // await saveXapiStatement(
        //   createXapiStatementD(state, { contentId: key.contentId, versionId: key.versionId }, progressReport)
        // );
        // END TEST

        const statements = await getXapiStatements(key2, "completed");
        const report = createTestReport(statements);
        await saveXapiStatement(
          createXapiStatementE(state, { contentId: key.contentId, versionId: key.versionId }, report)
        );
      } else {
        const report = createTestReport(statementAllCompleted);
        await saveXapiStatement(createXapiStatementE(state, rootActivityReferenceKey, report));
      }

      break;
    default:
      throw new Error("No valid xapi statement type");
  }

  if (response.length > 0) return response[0];

  return undefined;
};

const getRootActivityReferenceKey = (learningStructure: ExtendedTreeNode[] | undefined) => {
  const key = learningStructure?.find((x) => x.parentId === 0)?.activityKey;
  if (!key) throw new Error("Connot find key for activity root node");
  return key;
};

const createXapiStatementA = (statementId: string, state: LRSProviderState, assessmentState?: LRSProviderState) => {
  const segmentType: xApiActivityIdSegmentType = "question";

  const activity = createObjectActivity(state.referenceKey, segmentType);
  activity.definition = createActivityDefinition("Question", "choice"); // TODO: Check interactionType

  activity.definition.extensions = {
    [`http://${uriHost}/${segmentType}/referenceKey`]: state.referenceKey,
    [`http://${uriHost}/${segmentType}`]: { correctResponse: state.correctResponse, optionOrder: state.optionOrder },
  };

  if (assessmentState) {
    activity.definition.extensions[`http://${uriHost}/assessment/referenceKey`] = assessmentState.referenceKey;
  }

  const statement: Statement = {
    id: statementId,
    object: activity,
    context: createContext(),
    actor: createActor(state),
    verb: createVerb("answered"),
    result: createResult(state),
  };

  return statement;
};

const createXapiStatementB = (statementId: string, state: LRSProviderState, progress: any) => {
  const segmentType: xApiActivityIdSegmentType = "assessment";

  const activity = createObjectActivity(state.referenceKey, segmentType);
  activity.definition = createActivityDefinition("Assessment");

  activity.definition.extensions = {
    [`http://${uriHost}/${segmentType}/referenceKey`]: state.referenceKey,
    [`http://${uriHost}/${segmentType}`]: progress,
  };

  const statement: Statement = {
    id: statementId,
    object: activity,
    context: createContext(),
    actor: createActor(state),
    verb: createVerb("completed"),
    result: createResult(state),
  };

  return statement;
};

const createXapiStatementC = (
  state: LRSProviderState,
  rootActivityReferenceKey: ReferenceKey,
  progressedLearningObjectives: string[]
) => {
  const segmentType: xApiActivityIdSegmentType = "activity";

  const activity = createObjectActivity(rootActivityReferenceKey, segmentType);
  activity.definition = createActivityDefinition("Activity");

  activity.definition.extensions = {
    [`http://${uriHost}/${segmentType}/referenceKey`]: rootActivityReferenceKey,
    [`http://${uriHost}/learningObjective/id`]: progressedLearningObjectives,
  };

  const statement: Statement = {
    object: activity,
    context: createContext(),
    actor: createActor(state),
    verb: createVerb("progressed"),
  };

  return statement;
};

const createXapiStatementD = (state: LRSProviderState, referenceKey: ReferenceKey, report: ItemKnowledge) => {
  const segmentType: xApiActivityIdSegmentType = "activity";

  const activity = createObjectActivity(referenceKey, segmentType);
  activity.definition = createActivityDefinition("Activity");

  activity.definition.extensions = {
    [`http://${uriHost}/${segmentType}/referenceKey`]: referenceKey,
    [`http://${uriHost}/knowledgeReport`]: report,
  };

  const statement: Statement = {
    object: activity,
    context: createContext(),
    actor: createActor(state),
    verb: createVerb("reported-progress"),
  };

  return statement;
};

const createXapiStatementE = (state: LRSProviderState, referenceKey: ReferenceKey, report: ItemKnowledge) => {
  const segmentType: xApiActivityIdSegmentType = "activity";

  const activity = createObjectActivity(referenceKey, segmentType);
  activity.definition = createActivityDefinition("Activity");

  activity.definition.extensions = {
    [`http://${uriHost}/${segmentType}/referenceKey`]: referenceKey,
    [`http://${uriHost}/testReport`]: report,
  };

  const statement: Statement = {
    object: activity,
    context: createContext(),
    actor: createActor(state),
    verb: createVerb("reported-result"),
  };

  return statement;
};

const createXapiStatementF = (statementId: string, state: LRSProviderState) => {
  const segmentType: xApiActivityIdSegmentType = "assessment";

  const activity = createObjectActivity(state.referenceKey, segmentType);
  activity.definition = createActivityDefinition("Assessment");

  activity.definition.extensions = {
    [`http://${uriHost}/${segmentType}/referenceKey`]: state.referenceKey,
    [`http://${uriHost}/${segmentType}`]: state.questions,
  };

  const statement: Statement = {
    id: statementId,
    object: activity,
    context: createContext(),
    actor: createActor(state),
    verb: createVerb("attempted"),
    result: createResult(state),
  };

  return statement;
};

const getProgressReport = async (master: ItemAssessment, userId: string, referenceKey: ReferenceKey) => {
  // TODO: Add try catch
  const key = { userId: userId, contentId: referenceKey.contentId, versionId: referenceKey.versionId! };
  const statements = await getXapiStatements(key, "reported-progress", 1, 1);

  if (statements && statements.length === 1) {
    const activity = statements[0].object as Activity;
    const report: ItemKnowledge = activity.definition?.extensions![`http://${uriHost}/knowledgeReport`];
    return report;
  }

  return createProgressReport(master, []);
};

const getTestReport = async (userId: string, referenceKey: ReferenceKey) => {
  // TODO: Add try catch
  const key = { userId: userId, contentId: referenceKey.contentId, versionId: referenceKey.versionId! };
  const statements = await getXapiStatements(key, "reported-result", 1, 1);
  if (statements.length === 1) {
    const activity = statements[0].object as Activity;
    const report: ItemKnowledge = activity.definition?.extensions![`http://${uriHost}/testReport`];
    return report;
  }
  return createTestReport([]);
};

// Temporary solution.
const uniqueStatement = async (statement: Statement): Promise<Statement> => {
  if (!statement.id) return statement;
  const url = apiUrl + `statements/statement/${statement.id}`;
  const response = await get(url);
  if (response.length > 0) statement.id = uuidv4();
  return statement;
};

const saveXapiStatement = async (statement: Statement): Promise<string[]> => {
  const url = apiUrl + "statement";
  const s = await uniqueStatement(statement);
  const response: string[] = await post(url, s);
  return response;
};

type statementResponse = {
  pageInfo: {
    page: number;
    limit: number;
    total: number;
  };
  more: string;
  statements: Statement[];
};

const getXapiStatements = async (
  xapiKey: XapiKey,
  verb: string,
  page: number = 1,
  limit: number = 1000
): Promise<Statement[]> => {
  const url =
    apiUrl +
    `statements/${xapiKey.userId}/${xapiKey.contentId}/${xapiKey.versionId}/${verb}?page${page}&limit=${limit}`;
  const response: statementResponse = await get(url);
  // TODO: Check and act on paging.
  return response?.statements ?? [];
};

const getXapiStatement = async (id: string) => {
  const url = apiUrl + `statements/statement/${id}`;
  const response: string = await get(url);
  // TODO: Fix the incorrect response from the endpoint instead
  try {
    return JSON.parse(response) as Statement;
  } catch (e) {
    return null;
  }
};

export { createAndSaveXapiStatement, getXapiStatements, getXapiStatement, getProgressReport, getTestReport };
