import React from "react";
import { Box, Typography, Button, Link, Theme } from "@mui/material";
import { useRecoilValue, useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import { testId } from "tests/testIdStrings";
import { Icon, iconChevronDown, iconChevronUp, iconChevronLeftSolid, iconChevronRightSolid } from "theme/icons";
import { NavigationItem } from "utils/helpers/NavigationItem";
import { TheorySections } from "../../../layout/theory/sections";
import { theoryChapterState, theoryAreaState, theoryStateChildrenByParentId } from "state/theoryState";
import useChapterActivities from "hooks/useChapterActivities";
import useMediaQuery from "@mui/material/useMediaQuery";
import { FormattedTypography } from "utils/helpers/FormattedTypography";

type Props = {
  showSideMenu: boolean;
  setShowSideMenu: (bool: boolean) => void;
};

export const SideMenu: React.FC<Props> = ({ showSideMenu, setShowSideMenu }: Props) => {
  const navigate = useNavigate();
  const area = useRecoilValue(theoryAreaState);
  const chapters = useRecoilValue(theoryStateChildrenByParentId(area ? area.id : -1));
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const [chapterState, setChapterState] = useRecoilState(theoryChapterState);
  const { setChapterActivities } = useChapterActivities();

  const handleSelectedItem = (chapter: any) => {
    setChapterState(chapter);
    // För learning state
    setChapterActivities(chapter);
    const url = window.location.href;
    const splittedUrl = url.split("/");
    const currentTab = splittedUrl[splittedUrl.length - 1];
    const path = `theory/${area?.id}/${chapter.id}/${currentTab}`;
    navigate(path);
  };

  return (
    <Box
      className="side-menu"
      sx={{
        display: "flex",
        flexDirection: "row",
        position: "fixed",
        zIndex: 100,
        left: 0,
        top: 65,
        ".MuiListItem-root": {
          boxShadow: "none",
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          "> .MuiBox-root > .MuiBox-root .MuiListItemButton-root": {
            height: "3.75rem",
            ".MuiListItemText-root > .MuiTypography-root": {
              fontSize: "14px",
            },
          },
        },
      }}
    >
      <Box
        sx={{
          width: showSideMenu ? "30vw" : "70px",
          maxWidth: "330px",
          background: "white",
          borderBottomRightRadius: "15px",
          overflow: "hidden",
          boxShadow:
            "0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: "16px 19px 16px 19px",
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
            height: showSideMenu ? "70px" : "90vh",
            alignItems: showSideMenu ? "center" : "baseline",
          }}
        >
          <Typography variant="subtitle1" color="black">
            {showSideMenu && area?.title}
          </Typography>
          <Button
            onClick={() => setShowSideMenu(!showSideMenu)}
            variant="contained"
            sx={{
              width: "32px",
              height: "32px",
              minWidth: "auto",
              padding: 0,
            }}
          >
            <Icon name={showSideMenu ? iconChevronLeftSolid : iconChevronRightSolid} />
          </Button>
          {!showSideMenu && !isMobile && (
            <Box display="flex" flexDirection={"row"}>
              <Typography
                display={"flex"}
                fontSize={"12px"}
                marginLeft="30px"
                position={"absolute"}
                top="15px"
                color="black"
                width="200px"
              >
                <Link onClick={() => navigate("/theory")}>
                  <FormattedTypography
                    id="theory"
                    marginRight="3px"
                    fontSize={"12px"}
                    color={"primary.dark06"}
                    variant="body1"
                  />
                </Link>{" "}
                {" / "}
                {area?.title}
              </Typography>
            </Box>
          )}
        </Box>

        <Box
          sx={{
            display: "block",
            position: "relative",
            maxHeight: "calc(100vh - 160px)",
            overflowY: "auto",
          }}
        >
          {chapters.map((chapter, index) => {
            return (
              showSideMenu && (
                <NavigationItem
                  size="large"
                  borderRadius={"0px"}
                  key={chapter.id}
                  text={
                    <Typography variant="subtitle1" noWrap maxWidth="85%">
                      {chapter.title}
                    </Typography>
                  }
                  icon={[iconChevronDown, iconChevronUp]}
                  iconSize="medium"
                  onClick={() => handleSelectedItem(chapter)}
                  // progressValue={75}
                  dataTestIdString={`${testId.listItem.theory.chapters}-${index + 1}`}
                  showChildren={chapter.id === chapterState?.id ? true : undefined}
                >
                  <TheorySections chapterId={chapter.id} selectFirst={chapter.id === chapterState?.id} />
                </NavigationItem>
              )
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
