import React from "react";
import { Box } from "@mui/material";

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  currentIndex: number;
}

export const TabPanel: React.FC<TabPanelProps> = (props) => {
  const { children, currentIndex, index, ...other } = props;

  // Don't render content if current index matches
  if (currentIndex !== index) return <></>;

  return (
    <div
      role="tabpanel"
      id={`find-educator-tabpanel-${index}`}
      aria-labelledby={`find-educator-tab-${index}`}
      {...other}
    >
      <Box>{children}</Box>
    </div>
  );
};
