import React, { useEffect, useState, useRef } from "react";
import { appConfig } from "appConfig";
import { Stack, Box, Tab, Tabs, ButtonBase, useTheme } from "@mui/material";
import { styleFlexJustifyContentBetween } from "theme/styles";
import { FormattedTypography } from "utils/helpers/FormattedTypography";
import { Icon, iconCircleInfoSolid, iconListDots, iconMap, iconHeartSolid } from "theme/icons";
import { BackgroundShape } from "utils/helpers/BackgroundShape";
import { DialogMessage } from "utils/helpers/DialogMessage";
import { useFetch } from "hooks/useFetch";
import { Educator, Educators } from "types/educator";
import { API_URL_BO_EDUCATOR } from "utils/constants";
import EducatorsMap from "./educatorsMap";
import { TabPanel } from "./tabPanel";
import EducatorsList from "./educatorsList";
import { sortByDistance } from "utils/helpers/distanceHelper";
import pinEducator from "static/pinEducator.png";
import pinEducatorStr from "static/pinEducatorStr.png";
import SvgImage, { svgColor, ellipse1 } from "utils/helpers/SvgImage";
import { useAppIntl } from "services/useAppIntl";

// Landskrona
const fallBackLocation = {
  latitude: 55.8827361,
  longitude: 12.854968,
};

const centerOfSweden = {
  latitude: 61.987556758736574,
  longitude: 15.299610840325164,
};

export const FindEducators = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const tabsRef = useRef(null);
  const [tabContentHeight, setTabContentHeight] = useState(0);
  const theme = useTheme();
  const intl = useAppIntl();
  const { json: educatorsResponse, isLoading } = useFetch<Educators>(`${API_URL_BO_EDUCATOR}`);
  const [position, setPosition] = useState(centerOfSweden);
  const [sortedByDistance, setSortedByDistance] = useState<Educator[]>([]);
  const [openInfoBox, setOpenInfoBox] = useState(false);
  const showIsTestEducator = Boolean(appConfig.filterEducators);
  const filteredEducators = showIsTestEducator
    ? educatorsResponse?.educators.filter((e) => e.isReseller)
    : educatorsResponse?.educators.filter((e) => e.isReseller && !e.isTest);

  const calculateTabContentHeight = () => {
    if (tabsRef && tabsRef.current) {
      const tabsElement = tabsRef.current as Element;
      const tabsElementRect = tabsElement.getBoundingClientRect();
      const tabBottomY = tabsElementRect.bottom;

      setTabContentHeight(window.innerHeight - tabBottomY);
    }
  };

  useEffect(() => {
    if (!filteredEducators) return;

    calculateTabContentHeight();

    getLocationAndSortList(filteredEducators);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [educatorsResponse]);

  useEffect(() => {
    const handleResize = () => {
      calculateTabContentHeight();
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getLocationAndSortList = (educators: Educator[]) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          setPosition(pos.coords);
          setSortedByDistance(sortByDistance(pos.coords, educators));
        },
        (error) => {
          setSortedByDistance(sortByDistance(fallBackLocation, educators));
          console.error("Error getting user location:", error);
        },
        // options for better performance
        {
          maximumAge: 259200000, // 72 hours cache in milliseconds
          enableHighAccuracy: false,
        }
      );
    } else {
      setSortedByDistance(sortByDistance(fallBackLocation, educators));
      console.log("Position blocked by browser or device");
    }
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  if (isLoading || !filteredEducators) return null;

  return (
    <>
      <BackgroundShape top={-320} right={-300}>
        <SvgImage d={ellipse1.d} viewBox={ellipse1.viewBox} width="530" height="360" color={svgColor.pink} />
      </BackgroundShape>

      <Stack>
        <Box>
          <Box display="flex" sx={[styleFlexJustifyContentBetween]}>
            <FormattedTypography id="more.title.find.educator" variant="h1" mb={2} />
            <ButtonBase onClick={() => setOpenInfoBox(true)}>
              <Icon name={iconCircleInfoSolid} color="primary" sx={{ alignSelf: "baseline" }} />
            </ButtonBase>
          </Box>
          <FormattedTypography
            id={"educator.find.text"}
            lineHeight={1.2}
            values={{
              br: (
                <>
                  <br />
                  <br />
                </>
              ),
            }}
          />
        </Box>
        <Box sx={{ marginLeft: -3, marginRight: -3 }}>
          <Tabs
            ref={tabsRef}
            value={tabIndex}
            onChange={handleChange}
            centered
            variant="fullWidth"
            textColor="primary"
            indicatorColor="primary"
            TabIndicatorProps={{ sx: { height: "1px" } }}
          >
            <Tab
              label={intl.formatMessage({ id: "educator.find.map" })}
              icon={<Icon name={iconMap} />}
              iconPosition="start"
            />
            <Tab
              label={intl.formatMessage({ id: "educator.find.list" })}
              icon={<Icon name={iconListDots} />}
              iconPosition="start"
            />
          </Tabs>
          <TabPanel currentIndex={tabIndex} index={0}>
            <EducatorsMap
              centerOfSweden={centerOfSweden}
              dynamicHeight={tabContentHeight}
              educators={filteredEducators}
              position={position}
            />
          </TabPanel>
          <TabPanel currentIndex={tabIndex} index={1}>
            <EducatorsList dynamicHeight={tabContentHeight} educatorsSortedByDistance={sortedByDistance} />
          </TabPanel>
        </Box>
      </Stack>
      <DialogMessage
        title={"more.find.educator.info"}
        open={openInfoBox}
        close={() => setOpenInfoBox(false)}
        primaryButtonText={"common.close"}
        primaryButtonAction={() => setOpenInfoBox(false)}
        noCancelButton
      >
        <FormattedTypography id="more.find.educator.info.text" variant="body1" />
        <Stack spacing={3} ml={2}>
          <Box mt={2} display="flex" alignItems="center">
            <img src={pinEducatorStr} alt="" height={"48px"} />
            <FormattedTypography id="more.find.educator.info.icontext1" ml={1} />
          </Box>
          <Box display="flex" alignItems="center">
            <img src={pinEducator} alt="" height={"48px"} />
            <FormattedTypography id="more.find.educator.info.icontext2" ml={1} />
          </Box>
          <Box display="flex" alignItems="center">
            <Icon
              size={"large"}
              name={iconHeartSolid}
              sx={{ marginLeft: "5px", color: theme.palette.secondary.main }}
            />
            <FormattedTypography id="more.find.educator.info.icontext3" ml={1.5} />
          </Box>
        </Stack>
      </DialogMessage>
    </>
  );
};
