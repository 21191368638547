import { SyntheticEvent, useEffect, useState } from "react";
import { AppBar, Tab, Tabs, useTheme } from "@mui/material";
import {
  Icon,
  iconTheoryActive,
  iconTheory,
  iconCommentsQuestion,
  iconCommentsQuestionActive,
  iconPuzzle,
  iconPuzzleActive,
} from "theme/icons";
import { styleRoundedBottom } from "theme/styles";
import { Link, useLocation, useParams } from "react-router-dom";
import FormattedMessage from "../FormattedMessage";
import { useRouteConfiguration } from "hooks/useRouteConfiguration";
import { testId } from "tests/testIdStrings";

export enum TheoryTab {
  read = "read",
  practice = "practice",
  answer = "answer",
}

export const TopNavigationTabs = () => {
  const theme = useTheme();
  const location = useLocation();
  const { areaId, chapterId } = useParams();
  const [activeTab, setActiveTab] = useState<TheoryTab>(TheoryTab.read); /* Todo: temporary context*/
  const routes = useRouteConfiguration();

  const handleChange = (event: SyntheticEvent, newValue: TheoryTab) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    const tab = location.pathname.split("/").pop() as TheoryTab;
    setActiveTab(tab);
  }, [location]);

  return (
    <AppBar
      position="static"
      sx={[
        styleRoundedBottom,
        {
          backgroundColor: theme.palette.primary.main,
          boxShadow: "0",
        },
      ]}
    >
      <Tabs
        value={activeTab}
        onChange={handleChange}
        TabIndicatorProps={{ sx: { backgroundColor: "white", top: "0" } }}
        textColor="inherit"
        variant="fullWidth"
        aria-label="full width tabs example"
      >
        <Tab
          component={Link}
          to={routes.theoryRead.path.replace(":areaId", areaId!).replace(":chapterId", chapterId!)}
          icon={<Icon name={activeTab === TheoryTab.read ? iconTheoryActive : iconTheory} />}
          iconPosition="start"
          value={TheoryTab.read}
          data-testid={testId.menu.tab.read}
          label={<FormattedMessage id="common.title.read" />}
        />
        <Tab
          component={Link}
          to={routes.theoryPractice.path.replace(":areaId", areaId!).replace(":chapterId", chapterId!)}
          icon={<Icon name={activeTab === TheoryTab.practice ? iconPuzzleActive : iconPuzzle} />}
          iconPosition="start"
          value={TheoryTab.practice}
          data-testid={testId.menu.tab.practice}
          label={<FormattedMessage id="common.title.practice" />}
        />
        <Tab
          component={Link}
          to={routes.theoryAnswer.path.replace(":areaId", areaId!).replace(":chapterId", chapterId!)}
          icon={<Icon name={activeTab === TheoryTab.answer ? iconCommentsQuestionActive : iconCommentsQuestion} />}
          iconPosition="start"
          value={TheoryTab.answer}
          data-testid={testId.menu.tab.answer}
          label={<FormattedMessage id="common.title.answer" />}
        />
      </Tabs>
    </AppBar>
  );
};
